.SearchResultContentLoader {
  @apply w-full;
  height: 325px;

  .title {
    @apply rounded;
    width: 120px;
    height: 32px;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .body {
    @apply flex flex-col gap-y-4;
    padding: 2rem 0;

    .paragraph {
      @apply rounded;
      height: 10px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .pNormal {
      width: 100%;
    }

    .pEnd {
      width: 80%;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 95%);
  }
  100% {
    background-color: hsl(200, 20%, 90%);
  }
}
