.SearchResult__Contact {
  .item {
    @apply flex items-center gap-x-4;
  }

  .userinfo {
    @apply flex items-center gap-x-2 text-xs;
    .username {
      @apply truncate;
      width: 64px;
    }
  }

  .content {
    color: var(--dop-color-text-basic-level3, #9b9c9e);

    .bookItem {
      .label {
        @apply font-semibold;
        margin-right: 4px;
      }
    }

    .bookItem + .bookItem::before {
      content: '·';
      margin: 0 4px;
    }
  }
}
