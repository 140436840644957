.SearchResult__Mail {
  .item {
    @apply w-full flex items-center gap-x-3;
  }

  .content {
    @apply grow overflow-hidden;

    .username {
      @apply text-sm font-semibold;
    }
    .subject {
      @apply truncate text-xs;
    }

    .username,
    .subject {
      color: var(--dop-color-text-level2, #5b5b5b);
    }
  }

  .userinfo,
  .date {
    @apply shrink-0;
  }

  .date {
    @apply text-xs;
    color: var(--dop-color-text-level3, #999999);
  }
}
