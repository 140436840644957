.SearchResult__ApprovalItemType {
  @apply flex gap-x-4;

  .content.column {
    @apply flex flex-col gap-y-1;
  }

  .content.row {
    @apply flex gap-x-4;
  }
}

.SearchResult__ApprovalItemType_Footer {
  .info {
    @apply flex items-center gap-x-1;

    .label {
      @apply font-semibold;
    }
  }
}
