.Trigger {
  @apply flex items-center text-sm;
  gap: 10px;

  height: 40px;

  padding: 0px var(--dop-space-sm, 8px) 0px var(--dop-space-md, 12px);

  border-radius: 8px;
  border: 1px solid var(--dop-color-field-border-basic, #cfd1d4);
  background: var(--dop-color-field-bg, #fff);

  &:hover {
    border: 1px solid var(--dop-color-field-border-basic-hover, #9b9c9e);
  }

  &:disabled {
    color: var(--dop-color-text-basic-level3, #9b9c9e);
    border: 1px solid var(--dop-color-control-border-basic-disabled, #e1e3e6);
    background: var(--dop-color-control-bg-basic-disabled, #eaecef);
  }
}

.OptionContent {
  @apply flex flex-col text-sm font-medium;

  min-width: 240px;

  padding: var(--dop-space-sm, 8px);

  border-radius: 8px;
  background: var(--dop-color-bg-basic-base, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.Option {
  @apply w-full flex items-center text-sm font-medium;
  gap: 8px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;

  color: var(--dop-color-text-basic-level1, #1c1c1c);

  &:hover {
    background-color: var(--dop-color-bg-basic-base-hover, #f5f6f8);
  }
}

.Spacer {
  flex-grow: 1;
}

.SpacerChevron {
  flex-shrink: 0;
}
