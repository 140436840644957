.SearchResult__Calendar {
  @apply flex flex-col gap-y-4;

  :global(.txt_key) {
    color: var(--dop-color-text-status-information, #0d99ff);
    background-color: white !important;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
  }

  .item {
    @apply flex items-center gap-x-4;
    color: var(--dop-color-text-basic-level2, #4a4b4c);

    .date {
      @apply shrink-0;
      width: 120px;
    }
    .time {
      @apply shrink-0;
      width: 80px;
    }
    .summary {
      @apply grow truncate;
    }
    .attendees {
      @apply shrink-0 truncate text-sm;
      color: var(--dop-color-text-basic-level3, #9b9c9e);
      width: 180px;
    }
  }
}
