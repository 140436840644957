.SearchResult__Container {
  @apply flex flex-col gap-y-4;

  :global(.txt_key) {
    color: var(--dop-color-text-status-information, #0d99ff);
    background-color: white !important;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
  }
}

.SearchResult__Article {
  @apply flex flex-col gap-y-1;
}

.SearchResult__Article__Title {
  @apply text-base font-semibold cursor-pointer;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.SearchResult__Article__Content {
  @apply text-sm line-clamp-2 cursor-pointer;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.SearchResult__Article__Footer {
  @apply flex flex-wrap items-center text-xs;
  color: var(--dop-color-text-basic-level3, #9b9c9e);

  .footerItem + .footerItem::before {
    content: '·';
    margin: 0 4px;
  }
}

.SearchResult__Article__Userinfo {
  @apply flex items-center gap-x-1;
}

.SearchResult__Article__ContentPath {
  @apply flex items-center;

  .item + .item::before {
    content: '>';
    margin: 0 2px;
  }
}

.SearchResult__Article__AttachFile {
  @apply flex items-center text-xs gap-x-1;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

.SearchResult__Article__StatusTag {
  @apply flex items-center justify-center flex-nowrap truncate text-2xs;
  width: 53px;
  border-radius: 6px;
  padding: 6px;

  &.INPROGRESS {
    color: var(--dop-color-text-status-information, #0d99ff);
    border: 1px solid var(--dop-color-text-status-information, #0d99ff);
  }

  &.COMPLETE {
    color: var(--dop-color-text-status-neutral, #9b9c9e);
    border: 1px solid var(--dop-color-border-status-neutral, #9b9c9e);
  }

  &.RETURN {
    color: var(--dop-color-text-status-negative, #ee2e2e);
    border: 1px solid var(--dop-color-border-status-negative, #ee2e2e);
  }

  &.HOLD {
    color: var(--dop-color-text-status-caution, #fe7b1c);
    border: 1px solid var(--dop-color-border-status-caution, #fe7b1c);
  }

  &.DRAFT {
    color: var(--dop-color-text-status-special, #c271f3);
    border: 1px solid var(--dop-color-border-status-special, #c271f3);
  }
}
