.CompanySelector {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  color: var(--dop-color-border-basic-level2, #cfd1d4);
  border-radius: 6px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);

  &:hover {
    color: var(--dop-color-border-basic-level2-hover, #cfd1d4);
    border: 1px solid var(--dop-color-border-basic-level2-hover, #cfd1d4);
  }
}
